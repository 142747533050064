import React from 'react'
import NavBar from './Navbar'
import './Home.css'
import FirstPage from './FirstPage'
import Line from './Line'
import SecondPage from './SecondPage'
import Subscribe from './Subscribe'
import FirstPageNew from './FirstPageNew'
import Statistic from './Statistic'
import Footer from './Footer'
import Brand from './Brand'
import Brand2 from './Brand2'
import Services from './Services'

const Home = () => {
  return (
    <div>
        {/* <NavBar /> */}
        {/* <FirstPage /> */}
        <FirstPageNew />
        <Line />
        <Statistic />
        <SecondPage />
        <Services />
        <Brand/>
        <Brand2/>
        <Subscribe />
        <Footer />
    </div>
  )
}

export default Home